












































import {Component, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import Timesheet from '@/models/Timesheet';
import moment from 'moment';
import {saveAs} from 'file-saver';
import i18n from '@/i18n';

const UserStore = namespace('user');
const CustomerStore = namespace('customer');

@Component({
  components: {
    TableComponent: () => import(
        '@/components/shared/table/Table.component.vue'),
  },
})
export default class UserTimeSheetComponent extends Vue {

  @UserStore.Getter('activeUser')
  public _user!: User;

  get user(): User {
    return this._user;
  }

  public isDownloading: boolean = false;

  @UserStore.Action('loadUserTimesheetAction')
  private loadUserTimesheetAction!: (userId: string) => Promise<Timesheet[]>;
  @UserStore.Action('loadTimesheetAction')
  private loadTimesheetAction!: (timesheetId: string) => Promise<Blob>;

  public tableHeaders: any[] = [];
  public timesheetData: Timesheet[] = [];

  public async created() {
    this.tableHeaders = [
      {text: this.$t('GENERAL.UNITS.MONTH').toString(), value: 'month', sortable: true},
      {text: this.$t('GENERAL.UNITS.YEAR').toString(), value: 'year', sortable: true},
      {text: this.$t('GENERAL.JOBS_TIMESHEET').toString(), value: 'workSessionsCount', sortable: true},
      {text: this.$t('GENERAL.CLEAN_TIME_USER').toString(), value: 'totalWorkTime', sortable: true},
      {text: this.$t('GENERAL.LAST_CONFIRMATION').toString(), value: 'lastConfirmation', sortable: true},
      {text: '', value: 'options', width: 175, sortable: false},
    ];

    this.timesheetData = await this.loadUserTimesheetAction(this.user.id!);
  }

  private readonly screenWidth: string = screen.width - 90 - 72 + 'px';

  /**
   * Downloads the timesheet as pdf
   * @param item
   */
  public async downloadTimesheet(item: Timesheet) {
    // Try Downloading this Timesheet
    try {
      this.isDownloading = true;
      const response = await this.loadTimesheetAction(item.id);

      // Get File Name from Response Header by splitting the quotes and taking the second item inside the array
      let fileName;
      if (!response['content-disposition']) {
        fileName = `${this.$t('USER_DETAIL.TABS.TIMESHEET')}_${this.user.firstName}_${this.user.lastName}_${item.month}-${item.year}.pdf`;
      } else {
        fileName = (response['content-disposition'] as string).split('"')[1];
      }
      // file save lib method to save files without the href tag heck meck
      saveAs(response, fileName);
      this.isDownloading = false;
    } catch (e) {
      // Set Loading bar to false
      this.isDownloading = false;

      // Show error with this Status
      this.$notifyErrorSimplified(`TIMESHEET.ERRORS.${e.status}`);
    }
  }

  /**
   * Returns the humanized month of the given timesheet
   * @param timesheet
   */
  public getMonthName(timesheet: Timesheet) {
    return moment(timesheet.month, 'M').format('MMMM');
  }

  /**
   * Returns the humanized year of the given timesheet
   * @param timesheet
   */
  public getYearName(timesheet: Timesheet) {
    return moment(timesheet.year, 'Y').format('YYYY');
  }

  /**
   * Returns the workSessionsCount of the given timesheet
   * @param timesheet
   */
  public getWorkSessionsCount(timesheet: Timesheet) {
    return timesheet.workSessionsCount;
  }

  /**
   * get locale form i18n
   */
  get locale(): string {
    return i18n.locale;
  }
}
